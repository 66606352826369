import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ClaimFormsViewTab } from './claim-forms-tab'
import { ClientsInformationTab } from './customers-info-tab'
import { ClaimSubmissionsTab } from './claim-submissions-tab'
import { selectCurrentUser } from 'redux-slices/currentUserSlice'
import { PartnerReportsDownloadTab } from './reports-download-tab'
import { MonthlyReportsTab } from './monthly-reports-tab'

export const PartnerDashboard = () => {
  const { currentUser } = useSelector(selectCurrentUser)
  const location = useLocation()
  const pathname = location.pathname
  return (
    <div>
      <div>
        <div className="flex flex-col gap-4">
          <div className="flex justify-center">
            <h1 className="text-3xl text-gray-600 font-bold">
              {currentUser?.partner?.name.toUpperCase() || 'Partner'} Coverage
              Hub
            </h1>
          </div>

          {pathname === '/partner/dashboard' && <ClientsInformationTab />}
          {pathname === '/partner/dashboard/claim-forms' && (
            <ClaimFormsViewTab />
          )}
          {pathname === '/partner/dashboard/claim-submissions' && (
            <ClaimSubmissionsTab />
          )}
          {pathname === '/partner/dashboard/reports-download' && (
            <PartnerReportsDownloadTab />
          )}
          {pathname === '/partner/dashboard/monthly-reports' && (
            <MonthlyReportsTab />
          )}
        </div>
      </div>
    </div>
  )
}

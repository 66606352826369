import React, { useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

export const GenericModal = ({
  isOpen,
  onClose,
  title,
  children,
  footerActions = [],
  size = 'md',
  dismissable = true,
  backdrop = true,
  hideCloseButton = false,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          {backdrop && (
            <motion.div
              className="fixed inset-0 bg-black bg-opacity-50"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={dismissable ? onClose : undefined}
            />
          )}

          <motion.div
            className="fixed inset-0 flex items-center justify-center p-4 z-50"
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.3 }}
          >
            <div
              className={`relative bg-white shadow-xl rounded-lg p-6 w-full max-w-${size}`}
            >
              <div className="flex justify-between items-center">
                <h2 className="text-lg font-semibold">{title}</h2>
                {!hideCloseButton && (
                  <button
                    className="text-gray-500 hover:text-gray-700"
                    onClick={onClose}
                  >
                    ✕
                  </button>
                )}
              </div>

              <div className="mt-4">{children}</div>

              {footerActions.length > 0 && (
                <div className="mt-6 flex justify-end gap-2">
                  {footerActions.map(action => (
                    <button
                      key={action.label}
                      className={`px-4 py-2 text-sm font-medium rounded ${
                        action.color === 'danger' &&
                        'bg-red-500 text-white hover:bg-red-600'
                      } ${action.button_background ? action.button_background : 'bg-gray-200 text-gray-700 hover:bg-gray-300'} ${
                        action.disabled ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                      onClick={
                        !action.disabled ? action.onPress || onClose : undefined
                      }
                      disabled={action.disabled}
                    >
                      {action.label}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  )
}

import React from 'react'
import { DateRangePicker } from '@nextui-org/react'
import { parseDate } from '@internationalized/date'

export const DateRangePickerForDrawer = ({
  dateRange,
  onDateRangeSubmit,
  label = 'Select Date Range',
}) => {
  const safeParseDate = dateStr => {
    try {
      return parseDate(dateStr)
    } catch {
      return null
    }
  }

  const dateRangeValue =
    dateRange.date_start && dateRange.date_end
      ? {
          start: safeParseDate(dateRange.date_start),
          end: safeParseDate(dateRange.date_end),
        }
      : undefined

  const handleDateChange = range => {
    if (range?.start && range?.end) {
      onDateRangeSubmit({
        date_start: range.start.toString(),
        date_end: range.end.toString(),
      })
    }
  }

  return (
    <DateRangePicker
      label={label}
      value={dateRangeValue}
      onChange={handleDateChange}
      className="w-full"
    />
  )
}

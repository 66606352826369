import { useForm } from 'react-hook-form'
import { EyeIcon } from '@heroicons/react/outline'
import { usePagination } from 'hooks/usePagination'
import { Spinner, Tooltip } from '@nextui-org/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { usePartnersList } from 'hooks/usePartnersList'
import React, { useCallback, useMemo, useState } from 'react'
import { getParkguardCustomers } from 'services/adminService'
import { dateRangeSchema } from 'validations/dateRangeSchema'
import { DateRangeFilterForm } from 'components/generics/date-range-filter-form'
import { FilterDropdown } from 'components/generics/filter-dropdown/FilterDropDown'
import { SearchBarComponent } from 'components/generics/search-bar/SearchBarComponent'
import { PaginationComponent } from 'components/generics/pagination/PaginationComponent'
import { MultiSelectTableDropdown } from 'components/generics/multi-select-table-dropdown'
import { DefaultTableComponent } from 'components/generics/default-table/DefaultTableComponent'
import {
  formatDataForTable,
  formatDataDatesByList,
  formatDateRange,
} from 'helpers'
import {
  defaultColumnsToDisplay,
  allColumns,
  dateFields,
} from './customers-data-constants'

export const ParkGuardCustomersData = () => {
  const [searchValue, setSearchValue] = useState('')
  const [columnsToDisplay, setColumnsToDisplay] = useState(
    defaultColumnsToDisplay,
  )
  const { partnersList } = usePartnersList()
  const [selectedFilter, setSelectedFilter] = useState(null)

  const {
    data,
    totalPages,
    currentPage,
    isLoading,
    handlePageChange,
    handleSearch,
    handleDateRange,
    handleFilter,
  } = usePagination(getParkguardCustomers)

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(dateRangeSchema),
    defaultValues: { dateRange: [null, null] },
  })

  const tableData = useMemo(() => {
    const formattedData = formatDataDatesByList(data, dateFields)
    return formatDataForTable(formattedData, columnsToDisplay)
  }, [data, columnsToDisplay])

  const handleColumnSelectionChange = selectedColumns => {
    const columnsWithoutActions = selectedColumns.filter(
      col => col !== 'actions',
    )
    setColumnsToDisplay([...columnsWithoutActions, 'actions'])
  }

  const renderActions = useCallback(form => {
    return (
      <div className="flex gap-3 justify-center">
        <Tooltip content="View">
          <span className="cursor-pointer">
            <a
              href={`/view-customer-details/${form?.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <EyeIcon className="h-4 w-4" />
            </a>
          </span>
        </Tooltip>
      </div>
    )
  }, [])

  const onSearchInputChange = value => setSearchValue(value)

  const onSearchExecute = (refetch = false) => {
    refetch ? handleSearch('') : handleSearch(searchValue)
  }

  const onDateRangeSubmit = data => {
    const { date_start, date_end } = formatDateRange(data.dateRange)
    handleDateRange(date_start, date_end)
  }

  const onDateRangeCancel = () => {
    setValue('dateRange', [null, null])
    handleDateRange('', '')
  }

  const handleFilterChange = selectedOption => {
    setSelectedFilter(
      selectedOption?.value === selectedFilter ? '' : selectedOption?.value,
    )
    handleFilter(selectedOption?.value || '')
  }

  return (
    <>
      <div className="flex flex-col gap-4">
        {isLoading ? (
          <div className="flex justify-center py-24">
            <Spinner color="default" size="lg" />
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            <div className="flex items-center mt-4 pt-4">
              <div className="basis-8/12">
                <h2 className="text-2xl text-gray-600">
                  Customers Information
                </h2>
              </div>
              <div className="basis-1/6">
                <MultiSelectTableDropdown
                  allOptions={allColumns}
                  defaultSelectedOptions={defaultColumnsToDisplay}
                  onSelectionChange={handleColumnSelectionChange}
                  hideColumns={['id']}
                />
              </div>
              <div className="basis-1/6 px-4">
                <FilterDropdown
                  label="Filter by Partner"
                  options={partnersList}
                  value={selectedFilter}
                  onChange={handleFilterChange}
                  buttonClassName="text-nowrap "
                />
              </div>
              <DateRangeFilterForm
                control={control}
                errors={errors}
                handleSubmit={handleSubmit}
                onDateRangeSubmit={onDateRangeSubmit}
                onDateRangeCancel={onDateRangeCancel}
                watch={watch}
              />
              <div className="basis-1/6">
                <SearchBarComponent
                  value={searchValue}
                  onChange={onSearchInputChange}
                  onSearch={onSearchExecute}
                  inputWrapperClass="border-2 border-gray-500 rounded-lg"
                />
              </div>
            </div>

            <DefaultTableComponent
              columns={tableData.columns}
              data={tableData.data}
              renderActions={renderActions}
              isRowPopUpEnabled={false}
              columnsExclude={['ID']}
            />
          </div>
        )}
      </div>

      <PaginationComponent
        total={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        className="p-4 mt-3 flex flex-col items-end gap-x-2"
      />
    </>
  )
}

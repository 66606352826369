import { formatDateRange } from 'helpers'
import { useState, useCallback } from 'react'

export const useFilters = ({
  enableSearch = false,
  enableFilter = false,
  enableDateRange = false,
}) => {
  const [searchValue, setSearchValue] = useState('')
  const [selectedFilter, setSelectedFilter] = useState(null)
  const [dateRange, setDateRange] = useState({
    date_start: null,
    date_end: null,
  })

  const handleSearchInputChange = useCallback(
    value => setSearchValue(value),
    [],
  )

  const handleFilterChange = useCallback(
    selectedOption => {
      setSelectedFilter(
        selectedOption?.value === selectedFilter ? '' : selectedOption?.value,
      )
    },
    [selectedFilter],
  )

  const handleDateRangeSubmit = useCallback(data => {
    const { date_start, date_end } = formatDateRange([
      data?.date_start,
      data?.date_end,
    ])
    setDateRange({ date_start, date_end })
  }, [])

  const resetFilters = useCallback(() => {
    if (enableSearch) setSearchValue('')
    if (enableFilter) setSelectedFilter(null)
    if (enableDateRange) setDateRange({ date_start: null, date_end: null })
  }, [enableSearch, enableFilter, enableDateRange])

  return {
    searchValue,
    selectedFilter,
    dateRange,
    handleSearchInputChange,
    handleFilterChange,
    handleDateRangeSubmit,
    resetFilters,
  }
}

import React from 'react'

export const ClaimFormHeader = ({ logoSrc, partnerName }) => (
  <div>
    <div className="flex items-center gap-2 md:gap-3 mb-1 pb-4">
      <div className="flex basis-1/2 md:basis-1/3">
        <img src={logoSrc} alt="Logo" className="w-30 h-10" />
      </div>
      <div className="flex basis-1/3 items-start">
        {partnerName && (
          <div className="flex flex-col items-start gap-1">
            <span className="text-xs md:text-base text-gray-950 font-bold">
              {partnerName.toUpperCase()}
            </span>
            <span className="text-xs md:text-base text-gray-950 font-bold">
              Claim Form
            </span>
          </div>
        )}
      </div>
    </div>
    <div className="p-4">
      <span className="text-xs md:text-sm text-black text-left">
        To start a claim, please complete and submit the form below, even if all
        required information is not currently available.
      </span>
    </div>
  </div>
)

import React, { useEffect } from 'react'
import { Button } from '@nextui-org/react'
import { motion, AnimatePresence } from 'framer-motion'

const drawerSizeClasses = {
  sm: 'w-1/4',
  md: 'w-1/3',
  lg: 'w-1/2',
  full: 'w-full',
}

const drawerHeightClasses = {
  sm: 'inset-y-24 h-[70vh]',
  md: 'inset-y-16 h-[80vh]',
  lg: 'inset-y-8 h-[90vh]',
  full: 'h-[100vh]',
}

const drawerPositionClasses = {
  right: 'right-0',
  left: 'left-0',
  top: 'top-0 w-full h-auto',
  bottom: 'bottom-0 w-full h-auto',
}

const DrawerOverlay = ({ closeOnOverlayClick, onClose, children }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    className="fixed inset-0 z-50 bg-black bg-opacity-30 backdrop-blur-xs flex justify-end"
    onClick={closeOnOverlayClick ? onClose : undefined}
  >
    {children}
  </motion.div>
)

const DrawerContent = ({
  position,
  size,
  panelHeight = 'full',
  title,
  onClose,
  onApplyFilters,
  onClearFilters,
  className,
  children,
}) => (
  <motion.div
    initial={{ x: position === 'right' ? '100%' : '-100%' }}
    animate={{ x: 0 }}
    exit={{ x: position === 'right' ? '100%' : '-100%' }}
    transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    className={`fixed ${drawerPositionClasses[position]} ${drawerSizeClasses[size]} 
      ${drawerHeightClasses[panelHeight]} bg-white shadow-xl rounded-2xl p-6 flex flex-col ${className}`}
    onClick={e => e.stopPropagation()}
  >
    <DrawerHeader
      title={title}
      onClose={onClose}
      onClearFilters={onClearFilters}
    />
    <div className="flex-1 overflow-y-auto text-gray-700">{children}</div>
    <DrawerFooter onClose={onClose} onApplyFilters={onApplyFilters} />
  </motion.div>
)

const DrawerHeader = ({ title, onClearFilters }) => (
  <div className="flex justify-between items-center border-b pb-3 mb-4">
    <h2 className="text-lg font-semibold text-gray-700">{title}</h2>
    <div className="flex gap-2">
      <Button
        size="sm"
        color="warning"
        variant="bordered"
        onClick={onClearFilters}
      >
        Clear
      </Button>
    </div>
  </div>
)

const DrawerFooter = ({ onClose, onApplyFilters }) => (
  <div className="mt-4 flex justify-end gap-3 border-t pt-3">
    <button
      onClick={onClose}
      className="px-4 py-2 text-gray-600 bg-gray-100 rounded-lg hover:bg-gray-200 transition"
    >
      Cancel
    </button>
    <button
      onClick={onApplyFilters}
      className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
    >
      Apply
    </button>
  </div>
)

export const CustomDrawer = ({
  isOpen,
  onClose,
  title = 'Drawer',
  children,
  size = 'md',
  panelHeight = 'md',
  position = 'right',
  closeOnOverlayClick = true,
  className = '',
  onApplyFilters,
  onClearFilters,
}) => {
  useEffect(() => {
    document.body.classList.toggle('overflow-hidden', isOpen)
    return () => document.body.classList.remove('overflow-hidden')
  }, [isOpen])

  return (
    <AnimatePresence>
      {isOpen && (
        <DrawerOverlay
          closeOnOverlayClick={closeOnOverlayClick}
          onClose={onClose}
        >
          <DrawerContent
            position={position}
            size={size}
            title={title}
            onClose={onClose}
            onApplyFilters={onApplyFilters}
            onClearFilters={onClearFilters}
            className={className}
            panelHeight={panelHeight}
          >
            {children}
          </DrawerContent>
        </DrawerOverlay>
      )}
    </AnimatePresence>
  )
}

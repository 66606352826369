import React from 'react'
import { GenericModal } from 'components/generics/custom-modal'

export const InformationModal = ({
  isOpen,
  onClose,
  title,
  body,
  footerActions,
  dismissable = false,
  size = 'md',
  hideCloseButton = true,
}) => (
  <GenericModal
    isOpen={isOpen}
    onClose={onClose}
    title={title}
    size={size}
    dismissable={dismissable}
    hideCloseButton={hideCloseButton}
    footerActions={footerActions}
  >
    {body}
  </GenericModal>
)

import { Button } from '@heroui/react'
import { useSelector } from 'react-redux'
import { useFilters } from 'hooks/useFilters'
import { Funnel } from '@phosphor-icons/react'
import { usePagination } from 'hooks/usePagination'
import { Spinner, Tooltip } from '@nextui-org/react'
import { getFormTemplates } from 'services/partnerService'
import { copyToClipboard, formatDataForTable } from 'helpers'
import { columnsToDisplay } from './claim-form-tab-constants'
import React, { useCallback, useMemo, useState } from 'react'
import { selectCurrentUser } from 'redux-slices/currentUserSlice'
import { ClipboardCopyIcon, EyeIcon } from '@heroicons/react/outline'
import { FiltersSideDrawer } from 'components/generics/filters-drawer'
import { PaginationComponent } from 'components/generics/pagination/PaginationComponent'
import { DefaultTableComponent } from 'components/generics/default-table/DefaultTableComponent'

export const ClaimFormsViewTab = () => {
  const { currentUser } = useSelector(selectCurrentUser)
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false)

  const { searchValue, handleSearchInputChange, resetFilters } = useFilters({
    enableSearch: true,
    enableFilter: false,
    enableDateRange: false,
  })

  const {
    data,
    totalPages,
    currentPage,
    isLoading,
    handlePageChange,
    updateFiltersAndFetch,
  } = usePagination(getFormTemplates)

  const tableData = useMemo(
    () => formatDataForTable(data, columnsToDisplay),
    [data],
  )

  const renderActions = useCallback(
    form => {
      const url = `/${form?.url_suffix}`
      return (
        <div className="flex gap-3 justify-center">
          <Tooltip content="View">
            <div className="cursor-pointer">
              <a href={url} target="_blank" rel="noopener noreferrer">
                <EyeIcon className="h-4 w-4" />
              </a>
            </div>
          </Tooltip>
          <Tooltip content="Copy">
            <button
              className="cursor-pointer bg-transparent border-none"
              onClick={() => copyToClipboard('', url)}
              aria-label="Copy URL"
            >
              <ClipboardCopyIcon className="h-4 w-4" />
            </button>
          </Tooltip>
        </div>
      )
    },
    [currentUser],
  )

  const filtersConfig = {
    showFilterDropdown: false,
    showDateRangeFilter: false,
    showSearch: true,
  }

  const filterProps = {
    searchValue,
    onSearchInputChange: handleSearchInputChange,
  }

  const handleFilterApplyFromDrawer = () => {
    setIsFilterDrawerOpen(false)
    updateFiltersAndFetch(searchValue, null, null)
  }

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center py-24">
          <Spinner color="default" size="lg" />
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <div className="flex justify-between">
            <h2 className="text-2xl text-gray-600">Claim Form Templates</h2>
            <div className="basis-1/6">
              <Tooltip content="Open Filters">
                <Button
                  auto
                  type="button"
                  onPress={() => setIsFilterDrawerOpen(true)}
                  className="bg-transparent border-none p-0 h-6 w-6"
                >
                  <Funnel size={32} />
                </Button>
              </Tooltip>
              {isFilterDrawerOpen && (
                <FiltersSideDrawer
                  isOpen={isFilterDrawerOpen}
                  onClose={() => setIsFilterDrawerOpen(false)}
                  filtersConfig={filtersConfig}
                  filterProps={filterProps}
                  onApplyFilters={handleFilterApplyFromDrawer}
                  handleClearFilters={resetFilters}
                />
              )}
            </div>
          </div>
          <DefaultTableComponent
            columns={tableData.columns}
            data={tableData.data}
            renderActions={renderActions}
          />
        </div>
      )}
      <PaginationComponent
        total={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        className="p-4 mt-3 flex flex-col items-end gap-x-2"
      />
    </div>
  )
}
